/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        if (document.documentElement.clientWidth > 767) {
          $(document.body).addClass('open-special');
          $('#special').collapse('show');
        }

        $(window).scroll(function() {
          var scrollY = $(window).scrollTop();
          if(scrollY > 120) {
            $('.banner').addClass('sticky');
            $('#special').collapse('hide');
          } else if(scrollY < 120) {
            $('.banner').removeClass('sticky');
            if (document.documentElement.clientWidth > 767) {
              $('#special').collapse('show');
            }
          }
        });

        $('#special').on('show.bs.collapse', function() {
          $(document.body).addClass('open-special');
          $('#search').collapse('hide');
          if (document.documentElement.clientWidth < 768) {
            $('.navbar-collapse').collapse('hide');
          }
        });

        $('.navbar-collapse').on('show.bs.collapse', function() {
          if (document.documentElement.clientWidth < 768) {
            $('#special').collapse('hide');
          }
        });

        $('#search').on('show.bs.collapse', function() {
          $(document.body).addClass('open-search');
          $('#special').collapse('hide');
          $('.search-field').focus();
        });

        $('#special').on('hide.bs.collapse', function() {
          $(document.body).removeClass('open-special');
        });

        $('#search').on('hide.bs.collapse', function() {
          $(document.body).removeClass('open-search');
        });

        $('#addItem').on('show.bs.collapse', function() {
          $('.btn-add').addClass('hidden');
          $('.btn-close-add').removeClass('hidden');
        });

        $('#addItem').on('hide.bs.collapse', function() {
          $('.btn-add').removeClass('hidden');
          $('.btn-close-add').addClass('hidden');
        });

        $('#ytModal').on('show.bs.modal', function (event) {
          var button = $(event.relatedTarget);
          var src = button.data('src') + '&autoplay=1';
          var title = button.data('title');
          var modal = $(this);
          modal.find('.embed-responsive iframe').attr('src', src);
          modal.find('.modal-title .title').text(title);
        });

        $('#ytModal').on('hide.bs.modal', function (e) {
          var modal = $(this);
          modal.find('.embed-responsive iframe').attr('src', null);
          modal.find('.modal-title .title').text(null);
        });

        $('#couponswidget').carousel('pause');

        $('.sendtomail, .print').on('click', function() {
          formID = $(this).parents('.coupon').data('id');
          email = $(this).parents('.coupon').find('#your-email').val();
          username = $(this).parents('.coupon').find('#your-name').val();
          phone = $(this).parents('.coupon').find('#your-phone').val();
          permalink = $(this).parents('.coupon').data('permalink');
          $(this).parents('.coupon').find('.permalink').val(permalink+'?uname='+username+'&email='+email+'&phone='+phone);
        });

        $('.sendtomail').on('click', function() {
          action = "sendtomail";
        });

        $('.print').on('click', function() {
          action = "print";
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.pause').click( function() {
          $(this).addClass('hidden');
          $('.cycle').removeClass('hidden');
        });

        $('.cycle').click( function() {
          $(this).addClass('hidden');
          $('.pause').removeClass('hidden');
        });

        $('#headlines-carousel').carousel({
          interval: 7000
        });

        $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
          var oldTab = $(e.relatedTarget).data('id');
          var newTab = $(e.target).data('id');
          var src = $(e.target).data('src');
          $('#' + oldTab).attr('src', null);
          $('#' + newTab).attr('src', src);
        });

        setInterval(function() {
          if( $('.business-box li[role = presentation].active + li').length ) {
            $('.business-box li[role = presentation].active + li a').trigger('click');
          } else {
            $('.business-box li[role = presentation]:first a').trigger('click');
          }
        }, 3000);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
